<template>
  <div class="left-box">
    <!--    <a target="_blank" href="https://www.a4x.io/">-->
    <img class="left-icon" src="a4x_logo_3x.png" @click="toA4xIndex"/>
    <!--    </a>-->
    <img class="left-imag" src="login_left_img.png"/>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    toA4xIndex() {
      this.jumpWebPage("https://www.a4x.io/", true);
    }
  }
}
</script>

<style lang="scss" scoped>
.left-box {
  width: 503px;
  height: 100%;
  background: #F5F9FC;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //flex-grow: 0;
}

.left-icon {
  width: 130px;
  height: 42px;
  position: relative;
  left: 32px;
  top: 32px;
}

.left-icon:hover {
  cursor: pointer;
}

.left-img {
  width: 552px;
  height: 569px;
  margin-left: 7px;
}
</style>
