import { initArgs } from "@/utils/initArgs";

import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import base from "@/base"

import '@/styles/index.scss' // global css
import '@arco-design/web-vue/dist/arco.css';
import '@/permission' // permission control
import ArcoVue from '@arco-design/web-vue';

import LeftBox from "@/components/LeftBox";

createApp(App).mixin(base)
  .use(store).use(router).use(ArcoVue)
  // .use(ArcoVueIcon)
  .component("LeftBox", LeftBox)
  .mount("#app");
