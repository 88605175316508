import router from './router'
import store from './store'
import Message from "@arco-design/web-vue/es/message";
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
// import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/register', '/reset-password', '/develop'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  console.log('router beforeEach ', from.path, from.name, '->', to.path, to.name)
  // start progress bar
  NProgress.start()

  // set page title
  // document.title = getPageTitle(to.meta.title)
  document.title = to.name || "";

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {
      const hasGetUserInfo = store.getters.name
      if (hasGetUserInfo) {
        next()
      } else {
        try {
          // get user info
          await store.dispatch('user/info')

          next()
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          Message.error({
            content: error || "Error",
            duration: 5 * 1000
          });
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

// // google分析，页面调整
// router.afterEach((to, from, next) => {
//   window.gtag('config', 'G-W280N8BSER', {
//     page_title: to,
//     page_path: page,
//     page_location: location
//   })
// })
