import axios from "axios";
// import { MessageBox, Message } from '@/utils/mockElementUi'
import Message from "@arco-design/web-vue/es/message";
import Modal from "@arco-design/web-vue/es/modal";

import store from "@/store";
import { getToken } from "@/utils/auth";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30 * 1000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers["Authorization"] = getToken();
    }
    return config;
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data;

    if (res.code === 0) {
      // 接口调用成功
      return res;
    } else if (res.code === 401) {
      // token失效了，跳往登录界面
      Modal.warning({
        title: "Confirm logout",
        content: "You have been logged out, you can cancel to stay on this page, or log in again",
        okText: "Re-Login",
        cancelText: "Cancel",
        onOk: () => {
          store.dispatch("user/resetToken").then(() => {
            location.reload();
          });
        },
        onCancel: () => {
        }
      });
      return res;
    } else {
      // 接口调用失败
      Message.error({
        content: res.message || "Error",
        duration: 5 * 1000
      });
      return res;
    }
  },
  error => {
    console.error("invoke http error!", error); // for debug
    const res = { code: -2, message: "Failed to access the server!" };
    Message.error({
      content: res.message,
      duration: 5 * 1000
    });
    // return Promise.reject(error);
    return res;
  }
);

export default service;
