const state = {
  // env: 'local',
  // todo
  env: 'staging',
  baseUrl: {
    'local': 'http://localhost:9103',
    'staging': 'https://staging-cn-a4x-console.addx.live',
    'prod': 'https://prod-cn-a4x-console.addx.live'
  }
}

const mutations = {}

const actions = {
  // upload({ commit }, formData) {
  //   const baseUrl = state.baseUrl[state.env]
  //   const uploadUrl = baseUrl + '/aiDemo/upload'
  //
  // }
}

export default {
  namespaced: true,
  mutations,
  actions,
  state
}
