import { mapGetters } from "vuex";

export default {
  methods: {
    copyFields(src, des) {
      for (const key in src) {
        des[key] = src[key];
      }
    },
    assignFields(src, des) {
      for (const key in des) {
        des[key] = src[key] || "";
      }
    },
    readFileToDataUrl(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (res) => {
          const dataUrl = res.target.result;
          // console.log("readFileToDataUrl:", dataUrl);
          resolve(dataUrl);
        };
        reader.onerror = reject;
        reader.onabort = reject;
        reader.readAsDataURL(file);
      });
    },
    async timeout(millis) {
      await new Promise((resolve, reject) => setTimeout(resolve, millis));
    },
    toPrettyJson(obj) {
      return JSON.stringify(obj, null, 4);
    },
    // 如果项目不是部署在根路径上，就需要给资源的路径加上相应的前缀
    getAbsUrl(url) {
      return "/" + url;
    },
    getAbsUrls(obj) {
      const obj2 = {};
      for (const key in obj) {
        obj2[key] = this.getAbsUrl(obj[key]);
      }
      return obj2;
    },
    // 获取下一个元素
    getNextElement(node) {
      const nodes = node.parentNode.childNodes;
      for (let i = 1; i < nodes.length; i++) {
        if (nodes[i - 1] === node) {
          return nodes[i];
        }
      }
      return null;
    },
    jumpWebPage(url, openBlank) {
      console.log("jumpWebPage:", url, openBlank);
      window.open(url, openBlank ? "_blank" : null).location;
    }
  },
  computed: {
    ...mapGetters({
      envOptions: "envOptions"
    })
  }
};
