const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  // avatar: state => 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
  avatar: state => "../head_portrait.png",
  // windowSize: state => state.app.windowSize,
  token: state => state.user.token,
  email: state => state.user.userInfo.email,
  userInfo: state => state.user.userInfo,
  accountInfo: state => state.account.accountInfo,
  envOptions: state => state.account.envOptions,
  serverBaseUrl: state => state.server.baseUrl[state.server.env],
  currentAccount: state => state.account.currentAccount,
};
export default getters;
