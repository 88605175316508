// 验证构建参数

const initArgs = {
  nodeEnv: process.env.NODE_ENV,

  baseApi: process.env.VUE_APP_BASE_API,
  tenantId: process.env.VUE_APP_UNI_SMART_DEMO_TENANT_ID,
  accessKey: process.env.VUE_APP_UNI_SMART_DEMO_ACCESS_KEY,
  nodeName: process.env.VUE_APP_NODE_NAME,
};

console.log("console-web构建参数:", JSON.stringify(initArgs, null, 4));

export default initArgs;
