<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
  created() {
    console.log("App created!");
  },
  methods: {},
  beforeUnmount() {
    console.log("App beforeDestroy!");
  }
};
</script>
