import { createRouter, createWebHistory } from "vue-router";

let routes = [
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ "@/views/login/design")
  },
  {
    path: "/register",
    name: "Register",
    component: () => import(/* webpackChunkName: "register" */ "@/views/register/design")
  },
  {
    path: "/complete-info",
    name: "CompleteInfo",
    component: () => import(/* webpackChunkName: "register" */ "@/views/register/completeInfo")
  },
  {
    path: "/reset-password",
    name: "ResetPwd",
    component: () => import(/* webpackChunkName: "reset-password" */ "@/views/resetpwd/design")
  },
  {
    path: "/account-roles",
    name: "AccountRoles",
    component: () => import(/* webpackChunkName: "account-roles" */ "@/views/home/account-roles")
  },
  {
    path: "/home",
    redirect: "/person-info",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "@/views/home/home"),
    children: [
      {
        path: "/person-info",
        name: "PersonInfo",
        component: () => import(/* webpackChunkName: "person-info" */ "@/views/home/person-info")
      },
      {
        path: "/accounts",
        name: "AccountList",
        component: () => import(/* webpackChunkName: "account-list" */ "@/views/account/account-list")
      },
      {
        path: "/accounts/:accountId",
        name: "AccountDetail",
        component: () => import(/* webpackChunkName: "account-detail" */ "@/views/account/account-detail")
      },
      {
        path: "/accounts/:accountId/tenants/:tenantId",
        name: "TenantDetail",
        component: () => import(/* webpackChunkName: "tenant-detail" */ "@/views/tenant/tenant-detail")
      },
      {
        path: "/accounts/:accountId/tenants/:tenantId/webhooks",
        name: "Webhooks",
        component: () => import(/* webpackChunkName: "webhooks" */ "@/views/iot-essence/webhooks")
      },
      {
        path: "/accounts/:accountId/tenants/:tenantId/pir-config",
        name: "PirConfig",
        component: () => import(/* webpackChunkName: "pir-config" */ "@/views/iot-essence/pir-config")
      },
      {
        path: "/accounts/:accountId/tenants/:tenantId/device-config",
        name: "DeviceConfig",
        component: () => import(/* webpackChunkName: "device-config" */ "@/views/iot-essence/device-config")
      },
      {
        path: "/accounts/:accountId/tenants/:tenantId/notification-cert",
        name: "NotificationCert",
        component: () => import(/* webpackChunkName: "notification-cert" */ "@/views/iot-essence/notification-cert")
      },
      {
        path: "/accounts/:accountId/tenants/:tenantId/uni-smart",
        name: "UniSmart",
        component: () => import(/* webpackChunkName: "uni-smart" */ "@/views/uni-smart/index")
      },
      {
        path: "/experiments/uni-smart-demo",
        name: "UniSmartDemo",
        component: () => import(/* webpackChunkName: "uni-smart-demo" */ "@/views/experiments/uni-smart-demo")
        // component: () => import(/* webpackChunkName: "uni-smart-demo" */ "@/views/experiments/uni-smart-demo_20220314")
      }
    ]
  },
  {
    path: "/develop",
    name: "Develop",
    component: () => import(/* webpackChunkName: "develop" */ "@/views/develop/Develop")
  },
  {
    path: "/develop/exchange-code",
    name: "ExchangeCode",
    component: () => import(/* webpackChunkName: "exchange-code" */ "@/views/develop/ExchangeCode")
  },
  {
    path: "/develop/paas-device-config",
    name: "PaasDeviceConfig",
    component: () => import(/* webpackChunkName: "paas-device-config" */ "@/views/develop/PaasDeviceConfig")
  },
  {
    path: "/develop/paas-device-vip",
    name: "PaasDeviceVip",
    component: () => import(/* webpackChunkName: "paas-device-vip" */ "@/views/develop/PaasDeviceVip")
  },
  {
    path: "/develop/send-imgs",
    name: "SendImgs",
    component: () => import(/* webpackChunkName: "send-imgs" */ "@/views/develop/SendImgs")
  },
  {
    path: "/develop/wifi-power",
    name: "WifiPower",
    component: () => import(/* webpackChunkName: "wifi-power" */ "@/views/develop/WifiPower")
  },
  {
    path: "/develop/question-back",
    name: "QuestionBack",
    component: () => import(/* webpackChunkName: "question-back" */ "@/views/develop/QuestionBack")
  },
  {
    path: "/404",
    component: () => import("@/views/404")
  },
  // plan on directly navigating to the not-found route using its name
  { path: "/:pathMatch(.*)*", name: "not-found", redirect: "/404" },
  // if you omit the last `*`, the `/` character in params will be encoded when resolving or pushing
  { path: "/:pathMatch(.*)", name: "bad-not-found", redirect: "/404" }
];

if (process.env.VUE_APP_NODE_NAME.startsWith('prod')) { // 生产环境过滤掉这些工具
  routes = routes.filter(it => !it.path.startsWith("/develop"));
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
