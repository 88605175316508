import { createStore } from "vuex";
import getters from "@/store/getters";
import app from "@/store/modules/app";
import settings from "@/store/modules/settings";
import user from "@/store/modules/user";
import server from "@/store/modules/server";
import account from "@/store/modules/account";

export default createStore({
  modules: {
    app,
    settings,
    user,
    server,
    account
  },
  getters
});
