import { login, register, resetPassword, resendVerificationEmail, logout, getInfo } from "@/api/user";
import { getToken, setToken, removeToken } from "@/utils/auth";
// import { resetRouter } from '@/router/index'
import Cookies from "js-cookie";

const getDefaultState = () => {
  const userInfoJson = Cookies.get("userInfo");
  return {
    token: getToken(),
    userInfo: userInfoJson ? JSON.parse(userInfoJson) : {
      userId: null,
      email: "",
      companyName: "",
      industry: "",
      status: false
    }
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    console.log("SET_TOKEN:", token);
    state.token = token;
    setToken(token);
  },
  SET_USER_INFO: (state, userInfo) => {
    console.log("SET_USER_INFO:", userInfo);
    state.userInfo = userInfo;
    Cookies.set("userInfo", JSON.stringify(userInfo));
  }
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { email, password } = userInfo;
    return new Promise((resolve, reject) => {
      login({ email: email.trim(), password: password }).then(response => {
        const { data } = response;
        commit("SET_TOKEN", data.token);
        commit("SET_USER_INFO", data.user);
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },

  // user register
  register({ commit }, registerInfo) {
    const { email, password, companyName, industry, verifyCode } = registerInfo;
    const reqBody = {
      email: email.trim(), password: password.trim(), companyName: companyName.trim(),
      industry: industry.trim(), verifyCode: verifyCode.trim()
    };
    return new Promise((resolve, reject) => {
      register(reqBody).then(response => {
        console.log("after register! response:", (typeof response), response);
        const { data } = response;
        commit("SET_TOKEN", data.token);
        commit("SET_USER_INFO", data.user);
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },

  resetPassword({ commit }, registerInfo) {
    const { email, password, verifyCode } = registerInfo;
    const reqBody = { email: email.trim(), password: password.trim(), verifyCode: verifyCode.trim() };
    return new Promise((resolve, reject) => {
      resetPassword(reqBody).then(response => {
        console.log("after resetPassword! response:", (typeof response), response);
        const { data } = response;
        commit("SET_TOKEN", data.token);
        commit("SET_USER_INFO", data.user);
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },

  // user resendVerificationEmail
  async resendVerificationEmail({ commit }, registerInfo) {
    const response = await resendVerificationEmail(registerInfo);
    const { data } = response;
    commit("SET_EMAIL_VALID", data.emailValid);
    commit("SET_EMAIL_EXPIRED_TIME", data.emailExpiredTime);
    return response;
  },

  // get user info
  info({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        const { data } = response;
        if (!data) {
          return reject("Verification failed, please Login again.");
        }
        commit("SET_USER_INFO", data.user);
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        removeToken(); // must remove  token  first
        // resetRouter()
        commit("RESET_STATE");
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken(); // must remove  token  first
      commit("RESET_STATE");
      resolve();
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};

