const getDefaultState = () => {
  return {
    currentAccount: null,
    // envOptions: [{ id: "staging", title: "沙盒环境" }, { id: "prod", title: "生产环境" }]
    envOptions: [{ id: "staging", title: "Sand Box" }, { id: "prod", title: "Production" }]
  };
};

const state = getDefaultState();

const mutations = {
  SELECT_ACCOUNT: (state, account) => {
    console.log("mutation SELECT_ACCOUNT");
    state.currentAccount = account;
  }
};

const actions = {
  async select({ commit }, account) {
    console.log("action action.select");
    commit("SELECT_ACCOUNT", account);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
